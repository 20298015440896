import React, { useContext } from 'react'
import PageContext from '@context'
import SbEditable from 'storyblok-react'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import { Box, useMediaQuery } from '@material-ui/core'
import { Text, TextSm } from '@system'
import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import ACC_THEME from '@themes'
import multilinkToUrl from '@helpers/multilink-to-url'

const useStyles = makeStyles((theme) => ({
  meetUpCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: (props) =>
      props.blok.backgroundColor ? props.blok.backgroundColor.color : '',
    maxWidth: ({ cardModuleMaxWidth }) => cardModuleMaxWidth || '20rem',
    margin: (props) => (props.blok.removeMargin ? '0px' : '3rem 1rem'),
    textDecoration: 'none',
  },
  leftAlign: {
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  rightAlign: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  centerAlign: {
    textAlign: 'center',
  },
  imageContainer: {
    width: '100%',
  },

  title: (props) => ({
    color:
      !!props.blok.titleColor && !!props.blok.titleColor.color
        ? props.blok.titleColor.color
        : theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    padding: props.blok.noDescMargin ? '0px' : '20px 0',
    display: 'block',
  }),
  description: {
    color: theme.palette.text.tertiary,
    paddingBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '25px',
    },
    display: 'block',
  },
  link: {
    paddingBottom: '5px',
    textTransform: 'uppercase',
    marginTop: 'auto',
    marginBottom: '10px',
  },
  abmLink: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.tertiary,
    paddingBottom: '5px',
    textTransform: 'uppercase',
    marginTop: 'auto',
    '& a': {},
  },
  ctaModule: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    padding: '12px',
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    '& button': {
      padding: '4px 12px',
    },
    '& a': {
      padding: '4px 8px',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '1rem',
      display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: '1.5rem',
    },
  },
  noPaddingDescription: {
    paddingBottom: '0px',
  },
  meetUpCardLink: {
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
    },
  },
}))
const MeetupTopicCard = (props) => {
  const classes = useStyles(props)
  const {
    title,
    description,
    alignment,
    buttonAssetUrl,
    variant,
    image,
    noDescMargin,
    isClickable,
  } = props.blok
  const isInEditor = useContext(PageContext).isInEditor
  const { thumbnailHeight, cardModuleMaxWidth } = props
  const alignmentLeft = alignment === 'left'
  const alignmentCenter = alignment === 'center'
  const alignmentRight = alignment === 'right'
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  return (
    <SbEditable content={props.blok}>
      {!handHeldDevice ? (
        <>
          {!isClickable ? (
            <Box
              className={classNames(classes.meetUpCard, {
                [classes.leftAlign]: alignmentLeft,
                [classes.rightAlign]: alignmentRight,
                [classes.centerAlign]: alignmentCenter,
              })}
            >
              <Box>
                {!!image && image.length > 0 && (
                  <Box className={classes.imageContainer}>
                    {renderBloks(image, {
                      thumbnailHeight,
                      cardModuleMaxWidth,
                    })}
                  </Box>
                )}
                <Text
                  className={classNames(classes.title, {
                    [classes.noPaddingDescription]: noDescMargin,
                  })}
                >
                  {title}
                </Text>
                {!description ? (
                  <></>
                ) : (
                  <TextSm className={classes.description}>{description}</TextSm>
                )}
              </Box>
              <Box>
                <TextSm
                  component="span"
                  className={classNames(classes.link, classes[variant])}
                >
                  {renderBloks(buttonAssetUrl)}
                </TextSm>
              </Box>
            </Box>
          ) : (
            <Box
              component={GatsbyLink}
              rel={'noreferrer'}
              target={'_blank'}
              href={
                buttonAssetUrl[0].component === 'button'
                  ? multilinkToUrl(buttonAssetUrl[0].linksTo, isInEditor)
                  : multilinkToUrl(buttonAssetUrl[0].link, isInEditor)
              }
              className={classNames(classes.meetUpCard, {
                [classes.leftAlign]: alignmentLeft,
                [classes.rightAlign]: alignmentRight,
                [classes.centerAlign]: alignmentCenter,
              })}
            >
              <Box>
                {!!image && image.length > 0 && (
                  <Box className={classes.imageContainer}>
                    {renderBloks(image, {
                      thumbnailHeight,
                      cardModuleMaxWidth,
                    })}
                  </Box>
                )}
                <Text
                  className={classNames(classes.title, {
                    [classes.noPaddingDescription]: noDescMargin,
                  })}
                >
                  {title}
                </Text>
                {!description ? (
                  <></>
                ) : (
                  <TextSm className={classes.description}>{description}</TextSm>
                )}
              </Box>
              <Box className={classes.meetUpCardLink}>
                <TextSm
                  component="span"
                  className={classNames(classes.link, classes[variant])}
                >
                  {renderBloks(buttonAssetUrl)}
                </TextSm>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          {!isClickable ? (
            <Box
              style={{
                marginBottom: '24px',
              }}
              className={classNames(classes.meetUpCard, {
                [classes.leftAlign]: alignmentLeft,
                [classes.rightAlign]: alignmentRight,
                [classes.centerAlign]: alignmentCenter,
              })}
            >
              <Box>
                <Text
                  className={classNames(classes.title, {
                    [classes.noPaddingDescription]: noDescMargin,
                  })}
                >
                  {title}
                </Text>
                {!description ? (
                  <></>
                ) : (
                  <TextSm className={classes.description}>{description}</TextSm>
                )}
              </Box>
              <Box>
                <TextSm
                  component="span"
                  className={classNames(classes.link, classes[variant])}
                >
                  {renderBloks(buttonAssetUrl)}
                </TextSm>
              </Box>
            </Box>
          ) : (
            <Box
              component={GatsbyLink}
              rel={'noreferrer'}
              style={{
                marginBottom: '24px',
              }}
              target={'_blank'}
              href={
                buttonAssetUrl[0].component === 'button'
                  ? buttonAssetUrl[0].linksTo &&
                    multilinkToUrl(buttonAssetUrl[0].linksTo, isInEditor)
                  : buttonAssetUrl[0].link &&
                    multilinkToUrl(buttonAssetUrl[0].link, isInEditor)
              }
              className={classNames(classes.meetUpCard, {
                [classes.leftAlign]: alignmentLeft,
                [classes.rightAlign]: alignmentRight,
                [classes.centerAlign]: alignmentCenter,
              })}
            >
              <Box>
                <Text
                  className={classNames(classes.title, {
                    [classes.noPaddingDescription]: noDescMargin,
                  })}
                >
                  {title}
                </Text>
                {!description ? (
                  <></>
                ) : (
                  <TextSm className={classes.description}>{description}</TextSm>
                )}
              </Box>
              <Box className={classes.meetUpCardLink}>
                <TextSm
                  component="span"
                  className={classNames(classes.link, classes[variant])}
                >
                  {renderBloks(buttonAssetUrl)}
                </TextSm>
              </Box>
            </Box>
          )}
        </>
      )}
    </SbEditable>
  )
}

export default MeetupTopicCard
